<template>
  <CRow>
    <CCol col="12" xxl="8" xl="12">
      <CCard>
        <CCardHeader>
          <CButton class="float-left" disabled variant="ghost" color="dark">Remediation Items</CButton>
          <CButton class="ml-5 float-right"   @click="openHelp(true)" color="dark" :disabled="true"><span class="material-icons md-18">question_mark</span></CButton>
          <CButton class="ml-5 float-right col-sm-4 col-xxl-3" variant="outline" @click="openCreateRemediationItem" color="success">Create Remediation Item</CButton>
        </CCardHeader>      
        <CCardBody>         
          <CDataTable
            sorter            
            column-filter
            items-per-page-select
            hover
            striped
            :sorter-value="{ column: 'item_title', asc: 'true' }"            
            :items="items"
            :fields="fields"
            :items-per-page="20"
            :active-page="activePage"
            :pagination="{ doubleArrows: true, align: 'center'}"
            :loading=loading
          > 
              <td slot="item_lense" slot-scope="{item}">
                   {{  getLensName(item.item_lense) }}
              </td>
              <td slot="item_pillar" slot-scope="{item}">
                   {{ getPillarName(item.item_pillar) }}
              </td>              
              <td slot="item_edit" slot-scope="{item}">
                  <CButton v-on:click="openEditRemediationItem(item.item_id)" :id="item.item_id" block color="dark" style="display: flex;"><span class="material-icons md-18">edit</span></CButton>
              </td>
              <td slot="item_delete" slot-scope="{item}">
                  <CButton v-on:click="deleteRemediationItem(item.item_id)" :id="item.report_id" block color="danger" style="display: flex;"> <span class="material-icons md-18">delete</span></CButton>
              </td>                             
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import LensService from '@/services/lense.services';
import ItemService from '@/services/item.services';

export default {
  data() {
    return {
      lenses: [],
      pillars: [],
      items: [],
      fields: [
        { key: 'item_title', label: 'Title', _style:'width:50%' },
        { key: 'item_lense', label: 'Lense', _style:'width:25%' },
        { key: 'item_pillar', label: 'Pillar', _style:'width:20%' },
        { key: 'item_edit', label: '', sorter: false,  filter: false, _style:'width:1%' },
        { key: 'item_delete', label: '', sorter: false,  filter: false, _style:'width:1%' },
      ],
      loading: true,
      activePage: 1
    };
  },

  methods: {
    getLensName(lensAlias) {
      let lens = this.lenses.find(o => o.lens_alias === lensAlias);

      if (lens === undefined) {
        return lensAlias
      }

      return lens.lens_name;
    
    },    
    getPillarName(pillarId) {
      let pillar = this.pillars.find(o => o.pillar_id === pillarId);

      if (pillar === undefined) {
        return pillarId
      }

      return pillar.pillar_name;
    
    }, 
    deleteRemediationItem(id) {
     this.$router.push({path: '/remediation-items/'+id+'/delete'});
    },
    openCreateRemediationItem() {
      this.$router.push({path: '/remediation-items/create'});
    },
    openEditRemediationItem (item_id) {
      this.$router.push({path: `remediation-items/${item_id}`});
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    } ,
  },

  created() { },
  async mounted() {   
    await LensService.getLenses().then(
      response => { 
        this.lenses = response.lens_summaries;
      },
      error => {   
        console.log(error)   
      }      
    )
    await LensService.getPillars().then(
      response => { 
        this.pillars = response.pillars;

      },
      error => {   
        console.log(error)   
      }            
    )
    await ItemService.getItems().then(
      response => { 
        this.items = response;
      },
      error => {   
        console.log(error)   
      }      
    )     
    this.loading = false;


  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },


};

</script>
