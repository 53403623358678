var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xxl":"8","xl":"12"}},[_c('CCard',[_c('CCardHeader',[_c('CButton',{staticClass:"float-left",attrs:{"disabled":"","variant":"ghost","color":"dark"}},[_vm._v("Remediation Items")]),_c('CButton',{staticClass:"ml-5 float-right",attrs:{"color":"dark","disabled":true},on:{"click":function($event){return _vm.openHelp(true)}}},[_c('span',{staticClass:"material-icons md-18"},[_vm._v("question_mark")])]),_c('CButton',{staticClass:"ml-5 float-right col-sm-4 col-xxl-3",attrs:{"variant":"outline","color":"success"},on:{"click":_vm.openCreateRemediationItem}},[_vm._v("Create Remediation Item")])],1),_c('CCardBody',[_c('CDataTable',{attrs:{"sorter":"","column-filter":"","items-per-page-select":"","hover":"","striped":"","sorter-value":{ column: 'item_title', asc: 'true' },"items":_vm.items,"fields":_vm.fields,"items-per-page":20,"active-page":_vm.activePage,"pagination":{ doubleArrows: true, align: 'center'},"loading":_vm.loading},scopedSlots:_vm._u([{key:"item_lense",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(_vm.getLensName(item.item_lense))+" ")])}},{key:"item_pillar",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(_vm.getPillarName(item.item_pillar))+" ")])}},{key:"item_edit",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('CButton',{staticStyle:{"display":"flex"},attrs:{"id":item.item_id,"block":"","color":"dark"},on:{"click":function($event){return _vm.openEditRemediationItem(item.item_id)}}},[_c('span',{staticClass:"material-icons md-18"},[_vm._v("edit")])])],1)}},{key:"item_delete",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('CButton',{staticStyle:{"display":"flex"},attrs:{"id":item.report_id,"block":"","color":"danger"},on:{"click":function($event){return _vm.deleteRemediationItem(item.item_id)}}},[_c('span',{staticClass:"material-icons md-18"},[_vm._v("delete")])])],1)}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }